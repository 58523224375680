<template>
    <div class="contenedor" >
        
        <rutaPagina :ruta="ruta" />

        <div class="row glass panel"  >
            <div class="header-titulo">
                <div class="titulo">
                    <tituloPagina texto="Detalle entradas de mercancías/servicios" />                                        
                </div>
                <span class="estado-abierto" >Abierto</span>    
            </div>

            <div class="box-identificadores" >
                <div class="box-numero-entrada">
                    <i class="icono" ></i>
                    <div class="detalle" >
                        <p>N° entradas mercancias/servicios</p>
                        <strong>1246322</strong>
                    </div>
                </div>
                <div class="icono_separado">
                    <i></i>
                </div>
                <div class="box-numero-entrada">
                    <i class="icono-factura" ></i>
                    <div class="detalle" >
                        <p>N° orden de compra</p>
                        <strong>1246311</strong>
                    </div>
                </div>
            </div>
            
            <div class="contenedor-tabla">
                <div class="row" >
                    <div class="col-md-12 justificar-inicio" >
                        <a class="btn-descargar-formato"><i class='bx bx-cloud-download'></i>Descargar formato</a>
                    </div>
                </div>
                <div class="table-responsive">
                    <table id="tblDetalleOrdenCompra" class="tabla-ng">
                        <thead>
                            <tr class="omitir-bordes">
                                <th scope="col" >Código</th>
                                <th scope="col" >Descripción</th>
                                <th scope="col" >Medida</th>
                                <th scope="col" >Cant. ordenada</th>
                                <th scope="col" >Cant. pendiente</th>
                                <th scope="col" >Precio</th>
                                <th scope="col" >Descuento</th>
                                <th scope="col" >Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="omitir-bordes">
                                <td>PRV-0070</td>
                                <td>SOLFAC EC 050 BOTELLA x LT</td>
                                <td>UND</td>
                                <td>200.0</td>
                                <td>0.0</td>
                                <td>$ 197.000.000</td>
                                <td>$ 0.0</td>
                                <td>$ 197.000.000</td>
                            </tr>  
                            <tr class="omitir-bordes">
                                <td>PRV-0070</td>
                                <td>SOLFAC EC 050 BOTELLA x LT</td>
                                <td>UND</td>
                                <td>200.0</td>
                                <td>0.0</td>
                                <td>$ 197.000.000</td>
                                <td>$ 0.0</td>
                                <td>$ 197.000.000</td>
                            </tr>  
                            <tr class="omitir-bordes">
                                <td colspan="6"></td>
                                <td> SUBTOTAL  </td>
                                <td>$ 197.000.000</td>
                            </tr>  
                            <tr class="omitir-bordes">
                                <td colspan="6"></td>
                                <td>IVA</td>
                                <td>$ 0.0</td>
                            </tr>     
                            <tr class="omitir-bordes">
                                <td colspan="6"></td>
                                <td><b>TOTAL</b></td>
                                <td><b>$ 197.000.000</b></td>
                            </tr>        
                        </tbody>
                    </table>
                </div>                    
            </div>

            <div class="titulo">
                <tituloPagina texto="Anexos" />
            </div>

            <div class="contenedor-anexos">
                <div class="row">
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div class="box-anexo">
                            <i class='bx bx-image-alt'></i>
                            <small>Nombre archivo</small>
                            <small>Tamaño : 24kb</small>
                            <small>Fecha : 02/11/2021</small>
                            <span>Descargar <i class='bx bxs-download' ></i></span>
                        </div>
                    </div> 
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div class="box-anexo">
                            <i class='bx bx-image-alt'></i>
                            <small>Nombre archivo</small>
                            <small>Tamaño : 24kb</small>
                            <small>Fecha : 02/11/2021</small>
                            <span>Descargar <i class='bx bxs-download' ></i></span>
                        </div>
                    </div> 
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div class="box-anexo">
                            <i class='bx bx-image-alt'></i>
                            <small>Nombre archivo</small>
                            <small>Tamaño : 24kb</small>
                            <small>Fecha : 02/11/2021</small>
                            <span>Descargar <i class='bx bxs-download' ></i></span>
                        </div>
                    </div> 
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div class="box-anexo">
                            <i class='bx bx-image-alt'></i>
                            <small>Nombre archivo</small>
                            <small>Tamaño : 24kb</small>
                            <small>Fecha : 02/11/2021</small>
                            <span>Descargar <i class='bx bxs-download' ></i></span>
                        </div>
                    </div> 
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div class="box-anexo">
                            <i class='bx bx-image-alt'></i>
                            <small>Nombre archivo</small>
                            <small>Tamaño : 24kb</small>
                            <small>Fecha : 02/11/2021</small>
                            <span>Descargar <i class='bx bxs-download' ></i></span>
                        </div>
                    </div> 
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <div class="box-anexo">
                            <i class='bx bx-image-alt'></i>
                            <small>Nombre archivo</small>
                            <small>Tamaño : 24kb</small>
                            <small>Fecha : 02/11/2021</small>
                            <span>Descargar <i class='bx bxs-download' ></i></span>
                        </div>
                    </div> 
                                                                    
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import rutaPagina from '@/components/RutaPagina'
import tituloPagina from '@/components/Titulopagina'

export default {
    name:'Ruta pagina',
    setup(props) {

        const ruta = [
            { nombre : 'Documentos'},
            { nombre : 'Entradas de mercancía', ruta: '/mercancias'},
            { nombre : 'Detalle entradas de mercancía'},
        ]

        return{
            ruta,
        }
    },
    components:{
        rutaPagina,tituloPagina
    }
}
</script>

<style scoped>

</style>